<template>
    <div class="content">
        <h1>Page not found, stop trying to find bugs on my site</h1>
    </div>
</template>

<script>
export default {
    name: "PageNotFound",
    metaInfo: {
        title: "Page Not Found",
    },
}
</script>

<style lang="scss" scoped></style>
